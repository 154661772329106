import { Link } from 'gatsby';
import React from 'react';

const backgroundImage = (path) => ({ backgroundImage: `url(${path})` });

const Partial = ({}) => (
  <>
    <a id="blog"></a>
    <section className="section is-05">

      <img src={require('../../images/backgrounds/bg-content.png')} alt="" aria-hidden="true" className="bg-section-content" />

      <div className="container">
        <header className="section-header">
          <p className="text is-size-6 is-size-3-widescreen is-white has-text-right">
            Cápsulas espaciais de
          </p>

          <h1 className="title is-uppercase is-large is-white has-text-right">
            Conhecimento
          </h1>
        </header>

        <div className="v-space"></div>

        <div className="swiper-container swiper-container-cards is-overflow-initial">
          <div className="swiper-wrapper">

            <div className="swiper-slide">

              <a href="./interna.html" className="shadow">

                <article
                  className="card"
                  style={{ ...backgroundImage('./images/pics/card-hero.png') }}>
                  <header className="card-header">
                    <div className="media va-center">
                      <div className="media-left">

                        <span className="bullet">
                          <svg xmlns="http://www.w3.org/2000/svg" width="15.915" height="16" viewBox="0 0 15.915 16"><g id="blog" transform="translate(647 -4376)"><path id="Caminho_1169" data-name="Caminho 1169" d="M15.5,0H.415A.42.42,0,0,0,0,.426V15.569A.42.42,0,0,0,.411,16h11.4a.429.429,0,0,0,.306-.133L15.8,12.036a.423.423,0,0,0,.114-.3V.431A.42.42,0,0,0,15.506,0ZM.852.851H15.065V11.319H11.809a.439.439,0,0,0-.446.42v3.41H.852ZM14.5,12.17l-2.287,2.345V12.17Zm0,0" transform="translate(-647.001 4376)" fill="#fff"/><path id="Caminho_1170" data-name="Caminho 1170" d="M61.75,155.851h9.583a.426.426,0,0,0,0-.851H61.75a.426.426,0,0,0,0,.851Zm0,0" transform="translate(-705.715 4227.596)" fill="#fff"/><path id="Caminho_1171" data-name="Caminho 1171" d="M61.75,91.851H67.4a.426.426,0,0,0,0-.851H61.75a.426.426,0,1,0,0,.851Zm0,0" transform="translate(-705.715 4288.873)" fill="#fff"/><path id="Caminho_1172" data-name="Caminho 1172" d="M71.333,217.851a.426.426,0,0,0,0-.851H61.75a.426.426,0,0,0,0,.851Zm0,0" transform="translate(-705.715 4168.234)" fill="#fff"/></g></svg>
                        </span>

                      </div>

                      <div className="media-content">
                        <span className="is-uppercase has-text-white has-text-weight-semibold">
                          Blog
                        </span>
                      </div>

                    </div>
                  </header>

                  <footer className="card-footer">

                    <h2 className="title has-text-white is-size-5 has-text-weight-semibold">
                      Data or people for UX: qual melhor caminho a
                    </h2>

                  </footer>
                </article>

              </a>

            </div>

            <div className="swiper-slide">

              <a href="./interna.html" className="shadow">

                <article
                  className="card"
                  style={{ ...backgroundImage('./images/pics/card-01.png') }}>
                  <header className="card-header">
                    <div className="media va-center">
                      <div className="media-left">

                        <span className="bullet">
                          <svg id="_001-medium" data-name="001-medium" xmlns="http://www.w3.org/2000/svg" width="18.116" height="15.24" viewBox="0 0 18.116 15.24"><path id="Caminho_1406" data-name="Caminho 1406" d="M5.867,15.406a1.127,1.127,0,0,1-.479-.108L.952,13.229A1.652,1.652,0,0,1,0,11.737V1.3A1.132,1.132,0,0,1,1.616.275L6.052,2.344A1.652,1.652,0,0,1,7,3.837V14.274A1.138,1.138,0,0,1,5.867,15.406ZM1.132,1A.309.309,0,0,0,.823,1.3V11.737a.829.829,0,0,0,.474.746l4.436,2.069a.319.319,0,0,0,.443-.278V3.842A.829.829,0,0,0,5.7,3.1L1.266,1.027A.31.31,0,0,0,1.132,1Zm0,0" transform="translate(0 -0.167)" fill="#fff"/><path id="Caminho_1407" data-name="Caminho 1407" d="M176.832,101.175a1.127,1.127,0,0,1-.479-.108l-10.087-4.709.35-.746,10.087,4.709a.319.319,0,0,0,.437-.278V89.379h.823v10.664A1.137,1.137,0,0,1,176.832,101.175Zm0,0" transform="translate(-159.848 -85.935)" fill="#fff"/><path id="Caminho_1408" data-name="Caminho 1408" d="M202.412,13.04l-.685-.458,5.718-8.569a.463.463,0,0,0-.19-.679L202.7,1.208a.776.776,0,0,0-.937.257L198.552,6.3l-.685-.458,3.217-4.833a1.56,1.56,0,0,1,1.292-.695,1.6,1.6,0,0,1,.669.149L207.6,2.587a1.287,1.287,0,0,1,.525,1.884Zm0,0" transform="translate(-190.23 -0.307)" fill="#fff"/><path id="Caminho_1409" data-name="Caminho 1409" d="M156.574,73.874l.715-.409,5.431,9.483-.715.409Zm0,0" transform="translate(-150.531 -70.636)" fill="#fff"/></svg>
                        </span>

                      </div>

                      <div className="media-content">
                        <span className="is-uppercase has-text-white has-text-weight-semibold">
                          Medium
                        </span>
                      </div>

                    </div>
                  </header>

                  <footer className="card-footer">

                    <h2 className="title has-text-white is-size-5 has-text-weight-semibold">
                      Como melhorar continuamente o UX
                    </h2>

                  </footer>
                </article>

              </a>

            </div>

            <div className="swiper-slide">

              <a href="./interna.html" className="shadow">

                <article
                  className="card"
                  style={{ ...backgroundImage('./images/pics/card-02.png') }}>
                  <header className="card-header">
                    <div className="media va-center">
                      <div className="media-left">

                        <span className="bullet">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16.146" height="16" viewBox="0 0 16.146 16"><g id="podcats" transform="translate(647 -4251)"><path id="Caminho_1164" data-name="Caminho 1164" d="M16.145,10.173A8.074,8.074,0,0,0,2.364,4.464,8.022,8.022,0,0,0,0,10.173v3.822a3.925,3.925,0,0,0,3.919,3.919.465.465,0,0,0,.467-.467v-6.9a.465.465,0,0,0-.467-.467A3.906,3.906,0,0,0,.933,11.462V10.173a7.14,7.14,0,0,1,14.279,0,.429.429,0,0,0,.01.093.429.429,0,0,0-.01.093v1.289a3.906,3.906,0,0,0-2.986-1.382.465.465,0,0,0-.467.467v6.9a.465.465,0,0,0,.467.467,3.925,3.925,0,0,0,3.919-3.919V10.359a.429.429,0,0,0-.01-.093A.429.429,0,0,0,16.145,10.173Zm-12.7.874v5.9a2.984,2.984,0,0,1,0-5.9ZM12.7,17.129v-5.9a2.984,2.984,0,0,1,0,5.9Z" transform="translate(-647 4248.9)" fill="#fff"/></g></svg>
                        </span>

                      </div>

                      <div className="media-content">
                        <span className="is-uppercase has-text-white has-text-weight-semibold">
                          Podcast
                        </span>
                      </div>

                    </div>
                  </header>

                  <footer className="card-footer">

                    <h2 className="title has-text-white is-size-5 has-text-weight-semibold">
                      Empoderar o mundo para fazer o trabalho
                    </h2>

                  </footer>
                </article>

              </a>

            </div>

            <div className="swiper-slide">

              <a href="./interna.html" className="shadow">

                <article
                  className="card"
                  style={{ ...backgroundImage('./images/pics/card-03.png') }}>
                  <header className="card-header">
                    <div className="media va-center">
                      <div className="media-left">

                        <span className="bullet">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16.146" height="16" viewBox="0 0 16.146 16"><g id="podcats" transform="translate(647 -4251)"><path id="Caminho_1164" data-name="Caminho 1164" d="M16.145,10.173A8.074,8.074,0,0,0,2.364,4.464,8.022,8.022,0,0,0,0,10.173v3.822a3.925,3.925,0,0,0,3.919,3.919.465.465,0,0,0,.467-.467v-6.9a.465.465,0,0,0-.467-.467A3.906,3.906,0,0,0,.933,11.462V10.173a7.14,7.14,0,0,1,14.279,0,.429.429,0,0,0,.01.093.429.429,0,0,0-.01.093v1.289a3.906,3.906,0,0,0-2.986-1.382.465.465,0,0,0-.467.467v6.9a.465.465,0,0,0,.467.467,3.925,3.925,0,0,0,3.919-3.919V10.359a.429.429,0,0,0-.01-.093A.429.429,0,0,0,16.145,10.173Zm-12.7.874v5.9a2.984,2.984,0,0,1,0-5.9ZM12.7,17.129v-5.9a2.984,2.984,0,0,1,0,5.9Z" transform="translate(-647 4248.9)" fill="#fff"/></g></svg>
                        </span>

                      </div>

                      <div className="media-content">
                        <span className="is-uppercase has-text-white has-text-weight-semibold">
                          Podcast
                        </span>
                      </div>

                    </div>
                  </header>

                  <footer className="card-footer">

                    <h2 className="title has-text-white is-size-5 has-text-weight-semibold">
                      Empoderar o mundo para fazer o trabalho
                    </h2>

                  </footer>
                </article>

              </a>

            </div>

            <div className="swiper-slide">

              <a href="./interna.html" className="shadow">

                <article
                  className="card"
                  style={{ ...backgroundImage('./images/pics/card-02.png') }}>
                  <header className="card-header">
                    <div className="media va-center">
                      <div className="media-left">

                        <span className="bullet">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16.146" height="16" viewBox="0 0 16.146 16"><g id="podcats" transform="translate(647 -4251)"><path id="Caminho_1164" data-name="Caminho 1164" d="M16.145,10.173A8.074,8.074,0,0,0,2.364,4.464,8.022,8.022,0,0,0,0,10.173v3.822a3.925,3.925,0,0,0,3.919,3.919.465.465,0,0,0,.467-.467v-6.9a.465.465,0,0,0-.467-.467A3.906,3.906,0,0,0,.933,11.462V10.173a7.14,7.14,0,0,1,14.279,0,.429.429,0,0,0,.01.093.429.429,0,0,0-.01.093v1.289a3.906,3.906,0,0,0-2.986-1.382.465.465,0,0,0-.467.467v6.9a.465.465,0,0,0,.467.467,3.925,3.925,0,0,0,3.919-3.919V10.359a.429.429,0,0,0-.01-.093A.429.429,0,0,0,16.145,10.173Zm-12.7.874v5.9a2.984,2.984,0,0,1,0-5.9ZM12.7,17.129v-5.9a2.984,2.984,0,0,1,0,5.9Z" transform="translate(-647 4248.9)" fill="#fff"/></g></svg>
                        </span>

                      </div>

                      <div className="media-content">
                        <span className="is-uppercase has-text-white has-text-weight-semibold">
                          Podcast
                        </span>
                      </div>

                    </div>
                  </header>

                  <footer className="card-footer">

                    <h2 className="title has-text-white is-size-5 has-text-weight-semibold">
                      Empoderar o mundo para fazer o trabalho
                    </h2>

                  </footer>
                </article>

              </a>

            </div>

            <div className="swiper-slide">

              <a href="./interna.html" className="shadow">

                <article
                  className="card"
                  style={{ ...backgroundImage('./images/pics/card-03.png') }}>
                  <header className="card-header">
                    <div className="media va-center">
                      <div className="media-left">

                        <span className="bullet">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16.146" height="16" viewBox="0 0 16.146 16"><g id="podcats" transform="translate(647 -4251)"><path id="Caminho_1164" data-name="Caminho 1164" d="M16.145,10.173A8.074,8.074,0,0,0,2.364,4.464,8.022,8.022,0,0,0,0,10.173v3.822a3.925,3.925,0,0,0,3.919,3.919.465.465,0,0,0,.467-.467v-6.9a.465.465,0,0,0-.467-.467A3.906,3.906,0,0,0,.933,11.462V10.173a7.14,7.14,0,0,1,14.279,0,.429.429,0,0,0,.01.093.429.429,0,0,0-.01.093v1.289a3.906,3.906,0,0,0-2.986-1.382.465.465,0,0,0-.467.467v6.9a.465.465,0,0,0,.467.467,3.925,3.925,0,0,0,3.919-3.919V10.359a.429.429,0,0,0-.01-.093A.429.429,0,0,0,16.145,10.173Zm-12.7.874v5.9a2.984,2.984,0,0,1,0-5.9ZM12.7,17.129v-5.9a2.984,2.984,0,0,1,0,5.9Z" transform="translate(-647 4248.9)" fill="#fff"/></g></svg>
                        </span>

                      </div>

                      <div className="media-content">
                        <span className="is-uppercase has-text-white has-text-weight-semibold">
                          Podcast
                        </span>
                      </div>

                    </div>
                  </header>

                  <footer className="card-footer">

                    <h2 className="title has-text-white is-size-5 has-text-weight-semibold">
                      Empoderar o mundo para fazer o trabalho
                    </h2>

                  </footer>
                </article>

              </a>

            </div>

          </div>
        </div>

        <div className="v-space-lg"></div>

        <footer className="has-text-right">
          <a href="./blog.html" className="button is-primary is-rounded is-uppercase is-wide">
            Ver todos
          </a>
        </footer>
      </div>
    </section>
  </>
);

export default Partial;
